import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FaqComponent } from "../components/faq-body"
import { OrangeButton } from "../components/button-orange"

const FaqPage = (props) => {
  const data = useStaticQuery(graphql`
  query {
      allContentfulHomePage {
        edges {
          node {
            faqQuestion1
            faqQuestion2
            faqQuestion3
            faqQuestion4
            faqAnswer1
            faqAnswer2
            faqAnswer3
            faqAnswer4
          }
      }
    }
  }
  `)
  return (
    <Layout>
      <Seo
        title="FAQ - Iron West Group Affordable Heavy Equipment Sales"
        description="Find answers to some of our most frequently asked questions about our affordable Heavy Equipment Sales on this page!"
        location={props.location.pathname}
        isFaq={true}
      />
      <div className="pt-5 bg-lightgray">
        <FaqComponent data={data.allContentfulHomePage} />
        <p className="text-orange text-xl text-center max-w-lg mx-auto -mt-7 px-5">If you have any questions that are not answered above, feel free to contact us.</p>
        <div className="flex justify-center pt-5 pb-10">
          <OrangeButton buttonText="Contact Us" buttonPage="/contact" buttonTitle='Contact Iron West Group' buttonAlt='Iron West Group - Contact Us' />
        </div>
      </div>
    </Layout>
  )
}
export default FaqPage